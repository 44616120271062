import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { getHeaderLogoByPartner } from '../../components/homepage/campaign/bap';
import { getPryBtnColorByPartner } from '../../components/homepage/partner-config';
import { formatToCurrency } from '../../utils/util';
import PlanModal from './plan-modal';
export type PlanCardProps = {
  plan: {
    id: string;
    description: string;
    price: string;
    name: string;
    planType: string;
    plan_info: string[];
    plan_ussd_code: string;
    cover_value: string;
    plan_tnc: string;
  };
  duration: {
    id: number;
    type: string;
    isModal?: boolean;
  }[];
  activeTab: number;
};
const PlanCard = ({ plan, duration, activeTab }: PlanCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const router = useRouter();
  return (
    <Box
      key={plan?.id}
      id="w-node-_8aae2444-f0bc-d46a-2b79-3cc7bd82ad1d-df636ad8"
      data-w-id="8aae2444-f0bc-d46a-2b79-3cc7bd82ad1d"
      className="product-feature-item"
    >
      <Box className="product-feature-widget-title product-plans">
        <Box className="widget-square-box">
          <Box className="widget ai-notification-widget">
            {getHeaderLogoByPartner()}
            <Box className="heading-four">
              {plan?.name?.replace(/\bdevice\b/gi, '')}
            </Box>
            <Box className="heading-four">
              {formatToCurrency(plan?.price)}/
              {duration?.[activeTab]?.type?.toLowerCase()}
            </Box>
            <Flex gap={2}>
              <Box
                as="button"
                className="button plan-button bg-gray-dark w-inline-block"
                style={{
                  backgroundColor: getPryBtnColorByPartner(),
                }}
                onClick={() =>
                  duration?.[activeTab]?.isModal
                    ? onOpen()
                    : router.push({
                        pathname: `/device-care/plan`,
                        query: {
                          activeTab,
                          id: plan?.id,
                          price: plan?.price,
                          tnc: plan?.plan_tnc,
                        },
                      })
                }
              >
                <div className="button-text text-primary-1b">Buy Plan</div>
                <div className="button-effect bg-white"></div>
              </Box>

              <Link
                href={`/bap/${plan?.id}`}
                shallow
                className="button plan-button bg-primary-2b w-inline-block"
                style={{
                  backgroundColor: 'transparent',
                  border: `2px solid ${getPryBtnColorByPartner()}`,
                  color: getPryBtnColorByPartner(),
                }}
              >
                <Text className="button-text" fontSize="md">
                  View Details
                </Text>
                <Box className="button-effect bg-primary-3c"></Box>
              </Link>
            </Flex>
            <Box>
              Free device repairs worth {formatToCurrency(plan?.cover_value)}{' '}
              <br />
            </Box>
          </Box>
        </Box>
      </Box>
      <PlanModal plan={plan} isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default PlanCard;
