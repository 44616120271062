import { WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';
import { getHeaderLogoByPartner } from '../../components/homepage/campaign/bap';
import { getPryBtnColorByPartner } from '../../components/homepage/partner-config';
import { formatToCurrency } from '../../utils/util';

interface PlanCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  plan: {
    id: string;
    description: string;
    price: string;
    name: string;
    planType: string;
    plan_ussd_code: string;
    plan_info: string[];
    cover_value: string;
  };
}

const PlanModal = ({ isOpen, onClose, plan }: PlanCardModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box
              className="widget ai-notification-widget"
              maxW="400px !important"
              boxShadow="none"
              w="100%"
              p={0}
              m={0}
            >
              {getHeaderLogoByPartner()}
            </Box>
            <Box textAlign="center">
              <Heading fontSize="2xl" mb={0} lineHeight="3rem">
                {plan?.name?.replace(/\bdevice\b/gi, '')}
              </Heading>
              <Text>
                {formatToCurrency(plan?.price)}/{plan?.planType?.toLowerCase()}
              </Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              p={2}
              borderRadius={5}
              border={`1px solid ${getPryBtnColorByPartner()}`}
            >
              <Text textAlign="center" fontWeight="bold">
                <Box as="span" textTransform="capitalize">
                  {plan?.planType?.toLowerCase()}
                </Box>{' '}
                USSD Offer
              </Text>

              {[
                `Dial ${plan?.plan_ussd_code} on your device to select the ${plan?.name?.replace(
                  /\bdevice\b/gi,
                  ''
                )}Plan`,
                'You will receive a notification on your device, kindly follow through to complete your purchase.',
              ].map((info) => (
                <Flex alignItems="flex-start" gap={3} key={info} p={0} py={1}>
                  <Image
                    src="/images/interface-icon-check.svg"
                    id="w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9f-4cdf3bf8"
                    width="24"
                    height="24"
                    alt="Checkmark icon"
                  />
                  <Text
                    id="w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-4cdf3bf8"
                    className="comparison-table-description"
                  >
                    {info}
                  </Text>
                </Flex>
              ))}
            </Box>
            <Text textAlign="center" my={3}>
              Enjoy free device repairs worth{' '}
              {formatToCurrency(plan?.cover_value)} <br />
            </Text>
            <Flex alignItems="center" gap={4}>
              <WarningIcon cursor="pointer" />
              <Text fontSize="small">
                Kindly Note that all USSD Plans are paid through airtime
                deductions.
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PlanModal;
